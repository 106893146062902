<template>
    <!-- CONTENT ================================================== -->
    <div>

<!-- breadcrumb --> 
<div class="bg-light">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><a href="#"> <i class="fas fa-building"></i> </a></li>
          <li class="breadcrumb-item"> <i class="fas fa-chevron-right"></i> <a href="#">{{ translateAktif == 'id' ? 'Tentang Kami' : 'About'}}</a></li>
          <li class="breadcrumb-item active"> <i class="fas fa-chevron-right"></i> <span>{{ translateAktif == 'id' ? 'Mengapa Kami' : 'Why Us'}}</span></li>
        </ol>
      </div>
    </div>
  </div>
</div>
<!--=================================
breadcrumb -->
    
<section class="space-pb mt-4">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-title text-center">
          <h2>{{ translateAktif == 'id' ? 'Mengapa Kami' : 'Why Us'}}</h2>
          <p v-if="translateAktif == 'id'">Kami sudah berpengalaman puluhan tahun, dan sudah beberapa konsumen yang repeat order dengan 
            kami dalam kurun waktu yang lama. Kami juga mengedepankan kualitas produk dan pelayanan terbaik 
            bagi konsumen kami, respon cepat dan tanggap, adalah salah satu keunggulan kami.</p>
          <p v-if="translateAktif == 'en'">We have decades of experience, and have had several customers repeat orders with us
            us for a long time. We also prioritize product quality and the best service
            For our consumers, fast and responsive response is one of our advantages.</p>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-contract"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">{{ translateAktif == 'id' ? 'Berpengalaman' : 'Experienced'}}</h6>
            <p v-if="translateAktif == 'id'" class="mb-0">Pengalaman kami selama puluhan tahun menunjukan kualitas kami</p>
            <p v-if="translateAktif == 'en'" class="mb-0">Our decades of experience show our quality</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-like"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">{{ translateAktif == 'id' ? 'Produk Berkualitas' : 'Quality Products'}}</h6>
            <p v-if="translateAktif == 'id'" class="mb-0">Kualitas produk adalah prioritas kami untuk konsumen </p>
            <p v-if="translateAktif == 'en'" class="mb-0">Product quality is our priority for consumers </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-agent"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">{{ translateAktif == 'id' ? 'Pelayanan Terbaik' : 'Best service'}}</h6>
            <p v-if="translateAktif == 'id'" class="mb-0">Pelayanan terbaik menjadi ciri khas kami</p>
            <p v-if="translateAktif == 'en'" class="mb-0">The best service is our hallmark</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-time-call"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">{{ translateAktif == 'id' ? 'Respon Cepat' : 'Fast Response'}}</h6>
            <p v-if="translateAktif == 'id'" class="mb-0">Kapan pun anda butuhkan, kami siap beri tanggapan</p>
            <p v-if="translateAktif == 'en'" class="mb-0">Whenever you need, we are ready to respond</p>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <!-- <div class="row no-gutters">
      <div class="col-12">
          <center><h2>SWOT</h2></center>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-contract"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">Berpengalaman</h6>
            <p class="mb-0">Pengalaman kami selama puluhan tahun menunjukan kualitas kami</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-like"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">Produk Berkualitas</h6>
            <p class="mb-0">Kualitas produk adalah prioritas kami untuk konsumen </p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-agent"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">Pelayanan Terbaik</h6>
            <p class="mb-0">Pelayanan terbaik menjadi ciri khas kami</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-info">
          <div class="feature-info-icon">
            <i class="flaticon-time-call"></i>
          </div>
          <div class="feature-info-content">
            <h6 class="mb-3 feature-info-title">Respon Cepat</h6>
            <p class="mb-0">Kapan pun anda butuhkan, kami siap beri tanggapan</p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</section>

    </div>
</template>

<script>
    import axios from "axios";
    import {required} from "vuelidate/lib/validators";
    import { mapState } from 'vuex'

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Hasina',
            titleTemplate: '%s - Tentang Kami',
            meta: [
              {
                name: 'keywords',
                content: 'Hasina batik, Hasina konveksi, Hasina clothing, Hasina Merchandise, Hasina id'
              }
            ]
        },
        mounted() {
            this.getLocalStorage();
        },
        computed: mapState(['school_info']),
        data() {
            return {
                translateAktif: this.$root.$translateAktif,
                username: "",
                password: "",
                submitted: false
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        methods: {
            getImage(url){
                if (url.match(this.baseUrl)) {
                    return url;
                } else {
                    return this.baseUrl + url;
                }
            },
            handleSubmit() {
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form login',
                    });
                    return;
                } else {
                    this.$http.post(this.$apiconfig + 'admin/auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            if (response.data.status === "success") {
                                const getUser = response.data.user;
                                this.$router.push('/admin/dashboard');
                                if (getUser.require_twofactor == '1') {
                                    localStorage.setItem('name', getUser.fullname);
                                    localStorage.setItem('email', getUser.email);
                                    this.$router.push('/admin/twofactor');
                                } else {
                                    const token = getUser.key;
                                    localStorage.setItem('auth_token', token);
                                    axios.defaults.headers.common['Authorization'] = token;
                                    this.$router.push('/admin/dashboard');
                                }
                            } else {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                        .catch(function (error) {
                            // Swal.fire('Uh oh!', 'Please check your internet connection.', 'error')
                            console.debug("Login issue: " + error)
                        });
                }
            },
            getLocalStorage(){
                this.translateAktif = localStorage.getItem('translate');
            }
        }
    }
</script>